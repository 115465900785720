<script>
	export default {
		render( h ) {
			return h('v-toolbar', {
				attrs: {
					app: true,
					dark: true,
					fixed: true
				}
			}, [
				h('v-icon', {
					on: {
						click: oEv => {
							oEv.stopPropagation();
							this.$store.commit(
								'menuOpen', ! this.$store.state.menuOpen
							);
						}
					}
				}, ['mdi-menu']),
				h('v-toolbar-title', this.$slots.title),
				h('v-spacer'),
				this.$slots.actions
			]);
		}
	}
</script>
