<script>
	export default {
		render( h ) {
			return h('div', {
				style: {
					'flex': '1 0 auto',
					'display': 'flex',
					'flex-direction': 'column'
				}
			}, this.$slots.default);
		}
	}
</script>
