<script>
	export default {
		data: () => ({
			email: '',
			password: '',
			totp: '',
			totpError: null,
			totpRequired: false,
			verifyCode: '',
			verifyError: null,
			verifyRequired: false,
			staff: false,
			submitting: false,
			visible: false,
			error: null,
			onAuth: null,
			onCancel: null
		}),

		watch: {
			// {{{ visible

			visible( bValue ) {
				if ( bValue ) {
					requestAnimationFrame(() => {
						this.$refs.email.focus();
					});
				}
				else {
					this.email = '';
					this.password = '';
					this.totpRequired = false;
				}
			},

			// }}}
			// {{{ totpRequired

			totpRequired( bValue ) {
				if ( bValue ) {
					requestAnimationFrame(() => {
						this.$refs.totpField.focus();
					});
				}
				else {
					this.totp = '';
					this.totpError = null;
				}
			},

			// }}}
			// {{{ verifyRequired

			verifyRequired( bValue ) {
				if ( bValue ) {
					requestAnimationFrame(() => {
						this.$refs.verifyField.focus();
					});
				}
				else {
					this.verifyCode = '';
					this.verifyError = null;
				}
			}

			// }}}
		},

		methods: {

			// {{{ show( function onAuth, function onCancel, bool bStaff ) : void

			show( onAuth, onCancel, bStaff ) {
				this.onAuth = onAuth;
				this.onCancel = onCancel;
				this.staff = bStaff;
				this.visible = true;
			},

			// }}}
			// {{{ adminLogin( void ) : void

			adminLogin() {
				this.submitting = true;
				this.error = null;
				this.totpError = null;

				this.$systemAPI('login', {
						email: this.email,
						password: this.password,
						totp: this.totp
					})
					.then(oResp => {
						if ( oResp.totp_required || oResp.totp_invalid ) {
							this.submitting = false;
							this.totpRequired = true;

							if ( oResp.totp_invalid ) {
								this.totpError = 'Invalid authentication code';
							}
						}
						else if ( oResp.client_unknown ) {
							this.submitting = false;
							this.totpRequired = false;
							this.verifyRequired = true;
						}
						else {
							this.visible = false;

							if ( this.onAuth ) {
								this.onAuth();
							}
						}
					})
					.catch(oErr => {
						this.totpRequired = false;
						this.error = oErr.message;
						this.$refs.email.focus();
					})
					.finally(() => {
						this.submitting = false;
					});
			},

			// }}}
			// {{{ staffLogin( void ) : void

			staffLogin() {
				let oTab = window.open('/-/login/staff?autoclose=true'),
					nInterval = setInterval(() => {
						if ( ! oTab || oTab.closed ) {
							clearInterval(nInterval);

							this.visible = false;

							if ( this.onAuth ) {
								this.onAuth();
							}
						}
					}, 500);
			},

			// }}}
			// {{{ verifyClient( void ) : void

			verifyClient() {
				let sCode = this.verifyCode.trim();

				if ( ! sCode ) {
					this.verifyError = 'Please enter the verification code from your email';
					return;
				}

				this.submitting = true;
				this.verifyError = null;

				this.$systemAPI('login/verify', {
						code: sCode
					})
					.then(oResp => {
						this.visible = false;
						this.verifyRequired = false;

						if ( this.onAuth ) {
							this.onAuth();
						}
					})
					.catch(oErr => {
						this.error = oErr.message;
						this.verifyRequired = false;
					})
					.finally(() => {
						this.submitting = false;
					});
			}

			// }}}

		},

		render( h ) {
			return h('div', [
				// {{{ login dialog
				h('v-dialog', {
					attrs: {
						persistent: true,
						'max-width': 290
					},
					props: {
						value: this.visible
					},
					on: {
						input: bVisible => {
							this.visible = bVisible;
						}
					}
				}, (() => {
					let aChildren = [
							h('v-alert', {
								attrs: {type: 'error'},
								props: {value: this.error !== null}
							}, [this.error])
						];

					if ( this.staff ) {
						// {{{ staff login
						aChildren.push(
							h('v-card', [
								h('v-card-title', {
									class: ['headline']
								}, ['Login expired']),
								h('v-card-text', [
									h('p', ['Log back in to continue.'])
								]),
								h('v-card-actions', [
									h('v-spacer'),
									h('v-btn', {
										attrs: {
											flat: true
										},
										nativeOn: {
											click: () => {
												this.visible = false;

												if ( this.onCancel ) {
													this.onCancel();
												}
											}
										}
									}, ['Cancel']),
									h('v-btn', {
										attrs: {
											flat: true,
											color: 'primary'
										},
										on: {
											click: () => {
												this.staffLogin();
											}
										}
									}, ['Log in'])
								])
							])
						);
						// }}}
					}
					else {
						// {{{ admin login
						aChildren.push(
							h('v-card', [
								h('form', {
									on: {
										submit: oEv => {
											oEv.preventDefault();
											this.adminLogin();
										}
									}
								}, [
									h('v-card-title', {
										class: ['headline']
									}, ['Login expired']),
									h('v-card-text', [
										h('p', ['Please re-enter your credentials to continue.']),
										// {{{ email field
										h('v-text-field', {
											ref: 'email',
											attrs: {
												name: 'email',
												label: 'Email address',
												autocorrect: 'off',
												autocapitalize: 'off',
												'single-line': true,
												required: true
											},
											props: {
												value: this.email
											},
											on: {
												input: sValue => {
													this.email = sValue;
												}
											}
										}),
										// }}}
										// {{{ password field
										h('v-text-field', {
											attrs: {
												name: 'password',
												label: 'Password',
												type: 'password',
												'single-line': true,
												required: true
											},
											props: {
												value: this.password
											},
											on: {
												input: sValue => {
													this.password = sValue;
												}
											}
										})
										// }}}
									]),
									h('v-card-actions', [
										h('v-spacer'),
										// {{{ cancel button
										h('v-btn', {
											attrs: {
												flat: true
											},
											nativeOn: {
												click: () => {
													this.visible = false;

													if ( this.onCancel ) {
														this.onCancel();
													}
												}
											}
										}, ['Cancel']),
										// }}}
										// {{{ submit button
										h('v-btn', {
											attrs: {
												flat: true,
												color: 'primary',
												type: 'submit'
											}
										}, ['Log in'])
										// }}}
									])
								])
							])
						);
						// }}}
					}

					return aChildren;
				})()),
				// }}}
				// {{{ TOTP dialog
				h('v-dialog', {
					props: {
						value: this.totpRequired,
						maxWidth: 300,
						persistent: true
					}
				}, [
					h('v-card', [
						h('v-card-title', [
							h('span', {class: 'headline'}, [
								'Enter your 6 digit authentication code'
							])
						]),
						h('v-card-text', [
							h('v-alert', {
								attrs: {
									type: 'error',
									dismissible: false
								},
								props: {
									value: this.totpError !== null
								}
							}, [this.totpError]),
							h('v-text-field', {
								ref: 'totpField',
								props: {
									'single-line': true,
									solo: true,
									value: this.totp
								},
								on: {
									input: sCode => {
										this.totp = sCode;
									},
									keydown: oEv => {
										if ( oEv.key == 'Enter' ) {
											this.adminLogin();
										}
									}
								}
							})
						]),
						h('v-card-actions', [
							h('v-spacer'),
							h('v-btn', {
								attrs: {
									color: 'primary',
									type: 'submit'
								},
								on: {
									click: oEv => {
										oEv.preventDefault();
										this.adminLogin();
									}
								}
							}, [this.submitting ? 'Logging in...' : 'Log in']),
							h('v-btn', {
								on: {
									click: oEv => {
										oEv.preventDefault();
										this.totpRequired = false;
									}
								}
							}, ['Cancel'])
						])
					])
				]),
				// }}}
				// {{{ Verify dialog
				h('v-dialog', {
					props: {
						value: this.verifyRequired,
						maxWidth: 300,
						persistent: true
					}
				}, [
					h('v-card', [
						h('v-card-title', [
							h('span', {class: 'headline'}, [
								'New login detected'
							])
						]),
						h('v-card-text', [
							h('v-alert', {
								attrs: {
									type: 'error',
									dismissible: false
								},
								props: {
									value: this.verifyError !== null
								}
							}, [this.verifyError]),
							h('div', [
								'This appears to be the first time you have logged in from this device. A verification code has been sent to your account email address. Please enter the verification code to continue:'
							]),
							h('v-text-field', {
								ref: 'verifyField',
								props: {
									'single-line': true,
									solo: true,
									value: this.verifyCode
								},
								on: {
									input: sCode => {
										this.verifyCode = sCode;
									},
									keydown: oEv => {
										if ( oEv.key == 'Enter' ) {
											this.verifyClient();
										}
									}
								}
							})
						]),
						h('v-card-actions', [
							h('v-spacer'),
							h('v-btn', {
								attrs: {
									color: 'primary',
									type: 'submit'
								},
								on: {
									click: oEv => {
										oEv.preventDefault();
										this.verifyClient();
									}
								}
							}, [this.submitting ? 'Verifying...' : 'Verify']),
							h('v-btn', {
								on: {
									click: oEv => {
										oEv.preventDefault();
										this.verifyRequired = false;
									}
								}
							}, ['Cancel'])
						])
					])
				])
				// }}}
			]);
		}
	}
</script>
