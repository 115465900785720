import Vue from 'vue'
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.min.css'
import 'roboto-npm-webfont'

Vue.use(Vuetify, {iconfont: 'mdi'});

export default {}
