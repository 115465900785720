import Vue from 'vue'
import Vuex from 'vuex'
import XHR from './xhr'
import Reauth from './components/reauth.vue'

Vue.use(Vuex)

// {{{ Promise catchAuthFailure( function onAuth, array aArgs, Error oErr )

let catchAuthFailure = (function() {
	let oReauth = null;

	return function( onAuth, aArgs, oErr ) {
		if ( oErr.message != 'no-user-auth' )  throw oErr;

		// Begin new Promise to keep the original caller hanging.
		return new Promise((resolve, reject) => {
			if ( ! oReauth ) {
				let oMount = document.createElement('div');
				document.body.appendChild(oMount);
				let ReauthConstructor = Vue.extend(Reauth);
				oReauth = new ReauthConstructor;
				oReauth.$mount(oMount);
			}

			oReauth.show(resolve, () => {
				window.location = '/-/login';
			}, aArgs[0].getters.isStaff);
		})
		.then(() => {
			// Executed after successful reauth. The callback will start yet
			// another Promise when it replays the original request, thus
			// retaking control of the narrative.
			return onAuth.apply(this, aArgs);
		});
	};
})();

// }}}
// {{{ Promise systemAPI( object oContext, array aArgs )

function systemAPI( oContext, aArgs ) {
	let sAction = aArgs[0],
		oParams = aArgs[1];

	return XHR.json('/api/-/'+ sAction, oParams)
		.catch(catchAuthFailure.bind(this, systemAPI, [oContext, aArgs]));
}

// }}}
// {{{ Promise siteAPI( object oContext, array aArgs )

function siteAPI( oContext, aArgs ) {
	let oSite = oContext.getters.site,
		sAction = aArgs[0],
		oParams = aArgs[1];

	if ( ! oSite ) {
		throw new Error(
			'Unable to execute a site action without an active site'
		);
	}

	return XHR.json('/api/'+ oSite.domain +'/'+ sAction, oParams)
		.catch(catchAuthFailure.bind(this, siteAPI, [oContext, aArgs]));
}

// }}}
// {{{ Promise viewAction( object oContext, array aArgs )

function viewAction( oContext, aArgs ) {
	let sAction = aArgs[0],
		oConfig = aArgs[1] || {},
		oParams = oConfig.params || {},
		aPropNames = oConfig.props || [],
		oProps = {};

	if ( aPropNames == '*' ) {
		oProps = oContext.state.properties;
	}
	else if ( Array.isArray(aPropNames) ) {
		aPropNames.forEach(sName => {
			let xValue = oContext.getters.property(sName);

			if ( xValue !== undefined ) {
				oProps[sName] = xValue;
			}
		});
	}
	else {
		throw new Error('Invalid property name specification in viewAction');
	}

	oContext.commit('setViewError', null);

	if ( oConfig.onExecute ) {
		oContext.commit('updateProperties', oConfig.onExecute);
	}

	return siteAPI(oContext, ['view/action', {
		app: oContext.getters.app,
		view: oContext.getters.view,
		remnant: oContext.getters.remnant,
		action: sAction,
		params: oParams || {},
		props: oProps
	}]).then(oResp => {
		let oProps = oResp.props || {},
			sName;

		for ( sName in oProps ) {
			oContext.commit('setProperty', {
				name: sName,
				value: oProps[sName]
			});
		}

		if ( oConfig.onSuccess ) {
			oContext.commit('updateProperties', oConfig.onSuccess);
		}

		return oResp.resp || {};
	}).catch(oErr => {
		oContext.commit('setViewError', oErr.message);

		if ( oConfig.onFailure ) {
			oContext.commit('updateProperties', oConfig.onFailure);
		}

		throw oErr;
	}).finally(() => {
		if ( oConfig.onComplete ) {
			oContext.commit('updateProperties', oConfig.onComplete);
		}
	});
}

// }}}
// {{{ void systemBeacon( object oContext, array aArgs )

function systemBeacon( oContext, aArgs ) {
	if ( ! window.navigator.sendBeacon )  return;

	window.navigator.sendBeacon(
		'/api/-/'+ aArgs[0],
		JSON.stringify(aArgs[1] || {})
	);
}

// }}}
// {{{ void siteBeacon( object oContext, array aArgs )

function siteBeacon( oContext, aArgs ) {
	if ( ! window.navigator.sendBeacon )  return;

	let oSite = oContext.getters.site;
	if ( ! oSite )  return;

	window.navigator.sendBeacon(
		'/api/'+ oSite.domain +'/'+ aArgs[0],
		JSON.stringify(aArgs[1] || {})
	);
}

// }}}
// {{{ Promise upload( object oContext, array aArgs )

function upload( oContext, aArgs ) {
	let oFile = aArgs[0],
		onProgress = aArgs[1];

	return new Promise((resolve, reject) => {
		let oXHR = new XMLHttpRequest,
			oData = new FormData;

		oData.append('file[]', oFile);
		oData.append('fullID', '1');
		oData.append('extract', '1');

		if ( onProgress ) {
			oXHR.upload.addEventListener('progress', oEv => {
				onProgress(
					oEv.lengthComputable
						? Math.floor(oEv.loaded / oEv.total * 100)
						: 50
				);
			});
		}

		oXHR.upload.addEventListener('error', () => {
			reject(new Error(oXHR.statusText));
		});

		oXHR.addEventListener('load', () => {
			if ( oXHR.status === 200 ) {
				let oResp = JSON.parse(oXHR.responseText);

				if ( oResp.success ) {
					resolve(oResp.files[0]);
				}
				else {
					reject(new Error(oResp.error));
				}
			}
			else {
				reject(new Error(oXHR.statusText));
			}
		});

		oXHR.open('POST', '/-/upload');

		oXHR.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

		oXHR.send(oData);
	}).catch(catchAuthFailure.bind(this, upload, [oContext, aArgs]));
}

// }}}
// {{{ Promise loadSections( object oContext )

let loadSections = (function() {
	let bLoaded = false,
		bLoading = false;

	return function( oContext ) {
		return new Promise((resolve, reject) => {
			if ( bLoading || bLoaded ) {
				resolve();
			}
			else {
				bLoading = true;

				siteAPI(oContext, ['info/sections'])
					.then(oResp => {
						oContext.commit('setSections', oResp.sections);
						bLoaded = true;
						resolve();
					})
					.finally(() => {
						bLoading = false;
					});
			}
		});
	};
})();

// }}}
// {{{ Promise loadAppConfig( object oContext )

let loadAppConfig = (function() {
	let oLoaded = {},
		oLoading = {};

	return function( oContext ) {
		return new Promise((resolve, reject) => {
			let sApp = oContext.state.app;

			if ( ! sApp || oLoaded[sApp] || oLoading[sApp] ) {
				resolve();
			}
			else {
				oLoading[sApp] = true;

				siteAPI(oContext, ['info/app', {app: sApp}])
					.then(oResp => {
						oContext.commit('setAppConfig', oResp);
						oLoaded[sApp] = true;
						resolve();
					})
					.finally(() => {
						delete oLoading[sApp];
					});
			}
		});
	};
})();

// }}}
// {{{ Promise loadTemplate( object oContext, string sRoute )

function loadTemplate( oContext, sRoute ) {
	let aRoute = sRoute.replace(/^\//, '').split('/'),
		sType, sApp, sView, sRemnant, sCacheKey;

	if ( aRoute[0] == oContext.state.site.domain ) {
		// Site view
		sType = 'site';
		sApp = aRoute[1];
		sView = aRoute[2];
		sRemnant = aRoute.slice(3).join('/') || null;
	}
	else {
		// System view
		sType = 'system';
		sApp = aRoute[0];
		sView = aRoute[1];
		sRemnant = aRoute.slice(2).join('/') || null;
	}

	if ( ! sApp || ! sView ) {
		return new Promise((resolve, reject) => {
			reject(new Error('View app and name required'));
		});
	}

	sCacheKey = sType +'/'+ sApp +'/'+ sView;

	let oTemplate = oContext.state.templates[sCacheKey];

	if ( oTemplate ) {
		oContext.commit('setHasUnloadHandler', oTemplate.hasUnloadHandler);

		return new Promise((resolve, reject) => {
			resolve({
				type: sType,
				app: sApp,
				view: sView,
				remnant: sRemnant,
				cacheKey: sCacheKey,
				ui: oTemplate.ui
			});
		});
	}
	else {
		return systemAPI.call(this, oContext, ['view/template', {
				type: sType,
				app: sApp,
				view: sView
			}])
			.then(oResp => {
				oResp.cacheKey = sCacheKey;
				oResp.remnant = sRemnant;
				oContext.commit('cacheTemplate', oResp);
				return oResp;
			});
	}
}

// }}}

const oStore = new Vuex.Store({
	state: {
		menu: [],
		app: null,
		view: null,
		remnant: '',
		hasUnloadHandler: false,
		staffLoginAvailable: false,
		field59Upload: false,
		loginBanner: false,
		properties: {},
		permissions: {},
		templates: {},
		templateKeys: [],
		menuOpen: false,
		site: null,
		user: null,
		sections: [],
		appConfig: {},
		viewError: null
	},
	actions: {
		systemAPI,
		siteAPI,
		viewAction,
		upload,
		systemBeacon,
		siteBeacon,
		loadSections,
		loadAppConfig,
		loadTemplate
	},
	mutations: {
		initializeUI( oState, oValues ) {
			oState.menu = oValues.menu;
			oState.user = oValues.user;
			oState.site = oValues.site;
			oState.permissions = oValues.permissions || {};
			oState.staffLoginAvailable = oValues.staffLoginAvailable;
			oState.field59Upload = oValues.field59Upload;
			oState.loginBanner = oValues.loginBanner;
		},
		setView( oState, oValues ) {
			oState.app = oValues.app;
			oState.view = oValues.view;
			oState.remnant = oValues.remnant;
			oState.properties = oValues.properties;
		},
		setProperty( oState, oSet ) {
			if ( oSet.name ) {
				if ( oState.properties[oSet.name] === undefined ) {
					Vue.set(oState.properties, oSet.name, oSet.value);
				}
				else {
					oState.properties[oSet.name] = oSet.value;
				}
			}
		},
		updateProperties( oState, oProperties ) {
			let sKey;

			for ( sKey in oProperties ) {
				if ( oState.properties[sKey] === undefined ) {
					Vue.set(oState.properties, sKey, oProperties[sKey]);
				}
				else {
					oState.properties[sKey] = oProperties[sKey];
				}
			}
		},
		setSections( oState, aSections ) {
			oState.sections = aSections;
		},
		setAppConfig( oState, oConfig ) {
			if ( ! oConfig.app )  return;

			Vue.set(oState.appConfig, oConfig.app, {
				flags: oConfig.flags
			});
		},
		cacheTemplate( oState, oPayload ) {
			console.log('hasUnloadHandler', oPayload.hasUnloadHandler);
			oState.hasUnloadHandler = !! oPayload.hasUnloadHandler;

			oState.templates[oPayload.cacheKey] = {
				hasUnloadHandler: oState.hasUnloadHandler,
				ui: oPayload.ui
			};

			oState.templateKeys.push(oPayload.cacheKey);

			if ( oState.templateKeys.length > 50 ) {
				delete oState.templates[oState.templateKeys.shift()];
			}
		},
		setHasUnloadHandler( oState, bValue ) {
			oState.hasUnloadHandler = bValue;
		},
		menuOpen( oState, bValue ) {
			oState.menuOpen = bValue;
		},
		setViewError( oState, sValue ) {
			oState.viewError = sValue;
		}
	},
	getters: {
		menu( oState ) {
			return oState.menu.filter(oEntry => {
				if (
					! oEntry.app
					|| ! oEntry.permission
					|| (
						oState.permissions[oEntry.app]
						&& oState.permissions[oEntry.app].indexOf(
							oEntry.permission
						) != -1
					)
				) {
					return true;
				}
			}).sort((oLeft, oRight) => {
				if ( oLeft.category == oRight.category ) {
					if ( oLeft.weight > oRight.weight )  return -1;
					else if ( oLeft.weight < oRight.weight )  return 1;
					else  return 0;
				}
				else {
					return oLeft.category.localeCompare(oRight.category);
				}
			});
		},
		menuOpen( oState ) {
			return oState.menuOpen;
		},
		homeMenu( oState, oGetters ) {
			return oGetters.menu.filter(oEntry => {
				return !! oEntry.homeScreen;
			});
		},
		app( oState ) {
			return oState.app;
		},
		view( oState ) {
			return oState.view;
		},
		remnant( oState ) {
			return oState.remnant;
		},
		hasUnloadHandler( oState ) {
			return oState.hasUnloadHandler;
		},
		loginBanner( oState ) {
			return oState.loginBanner;
		},
		staffLoginAvailable( oState ) {
			return oState.staffLoginAvailable;
		},
		field59Upload( oState ) {
			return oState.field59Upload;
		},
		domain( oState ) {
			return oState.site ? oState.site.domain : null;
		},
		isStaff( oState ) {
			return oState.user ? oState.user.staff : false;
		},
		property( oState ) {
			return sName => oState.properties[sName];
		},
		query( oState ) {
			return (sName) => {
				if (
					oState.route
					&& oState.route.query
					&& oState.route.query[sName] !== undefined
				) {
					return oState.route.query[sName];
				}
				else {
					return null;
				}
			};
		},
		permission( oState ) {
			return (sApp, sPerm) => {
				return oState.permissions[sApp]
					&& oState.permissions[sApp].indexOf(sPerm) !== -1;
			};
		},
		appPermission( oState, oGetters ) {
			return sPerm => oState.app && oGetters.permission(oState.app, sPerm);
		},
		site( oState ) {
			if ( oState.site ) {
				return oState.site;
			}
			else if ( oState.route && oState.route.params.site ) {
				return {
					domain: oState.route.params.site
				};
			}
		},
		flags( oState ) {
			return (sAssetType) => {
				if (
					oState.app
					&& oState.appConfig[oState.app]
					&& oState.appConfig[oState.app].flags[sAssetType]
				) {
					return oState.appConfig[oState.app].flags[sAssetType].slice();
				}
				else {
					return [];
				}
			};
		},
		sections( oState ) {
			return oState.sections;
		},
		appViewURL( oState ) {
			return sView => {
				if ( oState.app && oState.site ) {
					return '/'+ oState.site.domain +'/'+ oState.app +'/'+ sView;
				}
			};
		},
		viewError( oState ) {
			return oState.viewError;
		}
	}
});

Vue.use({
	// Make commonly-used actions a little more accessible.
	//
	// Takes an action of the form:
	//     this.$store.dispatch('actionName', [xArg1, xArg2])
	//
	// ...and makes it available as:
	//     this.$actionName(xArg1, xArg2)
	install( Vue, oOpts ) {
		[
			'siteAPI', 'systemAPI', 'viewAction', 'upload',
			'systemBeacon', 'siteBeacon'
		].forEach(sAction => {
			Vue.prototype['$'+ sAction] = function() {
				// oStore must be used directly instead of this.$store as the
				// dynamically created reauth dialog's VM instance isn't
				// receiving this plugin's benefits.
				return oStore.dispatch(sAction, arguments);
			};
		});
	}
});

export default oStore;
