export default {
	json( sURL, oParams ) {
		return new Promise((resolve, reject) => {
			let oXHR = new XMLHttpRequest;

			oXHR.open('POST', sURL);

			oXHR.setRequestHeader(
				'Content-Type', 'application/json;charset=UTF-8'
			);

			oXHR.setRequestHeader(
				'X-Requested-With', 'XMLHttpRequest'
			);

			oXHR.addEventListener('load', oEv => {
				let oResp = JSON.parse(oXHR.responseText);

				if ( oResp.success ) {
					resolve(oResp);
				}
				else {
					reject(new Error(oResp.error));
				}
			});

			oXHR.addEventListener('error', oEv => {
				console.log('XHR error', oEv, oXHR);
				reject(new Error('HTTP error'));
			});

			oXHR.send(JSON.stringify(oParams || {}));
		});
	}
};
