// Basic Vue components
import Vue from 'vue'
import oStore from './store'
import oRouter from './router'
import {sync} from 'vuex-router-sync'
import VueGtm from '@gtm-support/vue2-gtm'

// Initialize Vue Material
import './ui'

// Custom components to be registered globally
import AppToolbar from './components/app/toolbar.vue'
import AppWrapper from './components/app/wrapper.vue'

// Register global custom components
Vue.component('app-toolbar', AppToolbar);
Vue.component('app-wrapper', AppWrapper);

// Automatically populate a `.route` module in the Vuex store with details
// about the current active route
sync(oStore, oRouter);

// Initialize app with basic site and user info emitted with the page
if ( window.tncms_init ) {
	oStore.commit('initializeUI', window.tncms_init);
	delete window.tncms_init;
}

Vue.use(VueGtm, {
  id: 'GTM-WXZNMN5',
  vueRouter: oRouter,
  debug: true
});

// Bootstrap app
new Vue({
	el: '#app',
	store: oStore,
	router: oRouter,
	render: h => h('router-view')
});
